import React, { useState } from 'react'
import Category from './components/category'
import Role from './components/role'
import { Container } from './styled'

export default function BodyFaq() {
  return (
    <Container>
      <Role />
      <Category />
    </Container>
  )
}
