import styled from 'styled-components';
import { colorPalette } from '../../../../../styles/colorSchema';

export const Container = styled.div`
  display:flex;
  justify-content:center;
  flex-direction:column;
  margin-left:auto;
  margin-right:auto;
  align-items:center;
  max-width:500px;
  padding-left:15px;
  padding-right:15px;
  color:${colorPalette.primary};
`;

export const Wrapper = styled.div`
  flex:1;
  height:10px;
  margin-left:auto;
  margin-right:auto;
  justify-content:center;
  align-items:center;
  max-width:400px;
`;

export const Info = styled.text`
  font-size:14px;
  padding:10px 0 10px 0;
  text-align:center;
  color: ${colorPalette.disabledColor};
`;