import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const Container = styled(Link)`
  display:flex;
  flex-direction:column;
  margin:10px;
  padding:10px;
  max-width:590px;
  max-height:100px;
  @media(max-width:768px){
    max-height:230px;
  }
  border: 1px solid ${colorPalette.cardColor};
  background-color: ${colorPalette.cardColor};
  text-decoration:none;
  list-style:none;
`;

export const PostItemTitle = styled.h4`
  color: ${colorPalette.primary};
`;

export const PostItemDescription = styled.p`
color: ${colorPalette.disabledColor};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px; /* fallback */
  max-height: 100px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: ${colorPalette.secondary};
`;