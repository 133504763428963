import React, { useEffect, useState } from 'react'
import { Container, Wrapper, Img as Image, WrapperMobile, Button } from './styled';
import { useMediaQuery } from '@material-ui/core';
import { useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import computerIcon from '../../../../images/computer-icon.svg'
import mobileIcon from '../../../../images/mobile-icon.svg'
import arrowIcon from '../../../../images/arrow-icon.svg'

export default function Questions() {
  const isMobile = useMediaQuery('(max-width:860px)');
  const [data, setData] = useState([]);
  const [isActive, setActive] = useState({ web: true, mobile: false });
  const { role, product } = useSelector(state => state.app);
  const { v4h, v4hAtendimento } = useStaticQuery(
    graphql`
      query{
        v4h: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/"}, frontmatter: {product: {eq: "V4H agenda"}}}) {
          edges {
            node {
              frontmatter {
                title
                description
                date(formatString: "DD [de ] MMMM [de] YYYY", locale: "pt-br")
                product
                role
                platform
              }
              fields{
                slug
              }
              timeToRead
            }
          }
        },
        v4hAtendimento: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/"}, frontmatter: {product: {eq: "V4H Atendimento"}}}) {
          edges {
            node {
              frontmatter {
                title
                description
                date(formatString: "DD [de ] MMMM [de] YYYY", locale: "pt-br")
                product
                role
                platform
              }
              fields{
                slug
              }
              timeToRead
            }
          }
        },
      }
    `
  )

  useEffect(() => {
    filterProduct();


  }, [role]);

  function filterProduct() {
    if (product === "V4H agenda") {
      setData(v4h.edges);
    } else {
      setData(v4hAtendimento.edges);
    }
  }
  function webRender() {
    let web
    switch (role.name) {
      case "Paciente":
        web = data.filter(element => ('web' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Paciente"))
        break;
      case "Gestor":
        web = data.filter(element => ('web' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Gestor"))
        break;
      case "Profissional da saúde":
        web = data.filter(element => ('web' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Profissional da saúde"))
        break;
      default:
        return;
    }

    if (!web.length) {
      return <text>Opss...ainda não temos essa informação</text>
    }
    let component = web.map(({ node: { frontmatter: { title }, fields: { slug } } }) => {
      return (
        <Wrapper to={slug}>
          <h4>{title}</h4>
          <Image src={arrowIcon} width={7} />
        </Wrapper>
      )
    });
    return component;
  }

  function mobileRender() {

    let mobile = data.filter(element => 'mobile' === element.node.frontmatter.platform)
    switch (role.name) {
      case "Paciente":
        mobile = data.filter(element => ('mobile' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Paciente"))
        break;
      case "Gestor":
        mobile = data.filter(element => ('mobile' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Gestor"))
        break;
      case "Profissional da saúde":
        mobile = data.filter(element => ('mobile' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Profissional da saúde"))
        break;
      default:
        return;
    }
    if (!mobile.length) {
      return <text>Opss...ainda não temos essa informação</text>
    }
    let component = mobile.map(({ node: { frontmatter: { title }, fields: { slug } } }) => {
      return (
        <Wrapper to={slug}>
          <a>{title}</a>
          <Image src={arrowIcon} width={7} />
        </Wrapper>
      )
    });
    return component;
  }

  function renderQuestionsWeb() {
    let permission = 'paciente';
    return (
      <Container>
        <div>
          <div>
            <Image src={computerIcon} width={20} />
            <text>Computador(PC)</text>
          </div>
          {webRender(permission)}
        </div>
        <div>
          <div>
            <Image src={mobileIcon} width={13} />
            <text>Celular</text>
          </div>
          {mobileRender(permission)}
        </div>
      </Container>
    );
  }
  function handleClick() {

    setActive(true);

  }

  function renderQuestionsMobile() {

    return (
      <WrapperMobile>
        <div>
          <Button active={isActive.web} onClick={() => setActive({ web: true, mobile: false })}>
            <Image src={computerIcon} width={20} />
            <text>Computador(PC)</text>
          </Button>

          <Button active={isActive.mobile} onClick={() => setActive({ web: false, mobile: true })}>
            <Image src={mobileIcon} width={13} />
            <text>Celular</text>
          </Button>
        </div>
        { isActive.web ? webRender() : mobileRender()}
      </WrapperMobile>
    );
  }
  return (<div>
    {isMobile ? renderQuestionsMobile() : renderQuestionsWeb()}
  </div>)

}
