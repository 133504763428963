import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Container, Info } from './styled';
import Questions from '../../questions';
import Tutorials from '../../tutorials';
import Posts from '../../posts';
import { colorPalette } from '../../../../../styles/colorSchema';


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 280,
    color: colorPalette.primary,
  },
}));

export default function CategoryMobile() {
  const classes = useStyles();
  const [option, setOption] = React.useState(10);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setOption(event.target.value);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const renderPage = () => {
    switch (option) {
      case 10:
        return <Questions />
      case 20:
        return <Tutorials />
      case 30:
        return <Posts />
      default:
        return <text>ERROR</text>
    }
  }

  return (
    <Container>
      <FormControl className={classes.formControl} >
        <InputLabel id="demo-controlled-open-select-label">Selecionar</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={option}
          style={
            {
              color: colorPalette.primary,
              fontSize: 20,
              fontWeight: '500',
            }
          }
          onChange={handleChange}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          <MenuItem value={10}>Principais dúvidas</MenuItem>
          <MenuItem value={20}>Tutoriais</MenuItem>
          <MenuItem value={30}>Artigos</MenuItem>
        </Select>
      </FormControl>
      <Info>Confira abaixo as dúvidas mais
      recorrentes entre nossos usuários
      de acordo com o tipo de
        plataforma.</Info>
      {renderPage()}
    </Container>
  );
}
