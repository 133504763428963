import styled from 'styled-components';
import { colorPalette } from '../../../../styles/colorSchema';

export const Container = styled.div`
  display:flex;
  flex:1;
  align-items:center;
  justify-content:space-around;
`;

export const Wrapper = styled.button`
  display:flex;
  min-width: 120px;
  align-items:center;
  justify-content:center;
  height:28px;
  margin:15px;
  @media (max-width:860px) {
    margin:15px 0px 10px 0px;
    min-width:75px;
  }
  background-color: ${colorPalette.white};
  border:none;
  outline:none;
  cursor: pointer;
  border-bottom: ${({ active }) => active ? `5px solid ${colorPalette.primary}` : "none"};
  :hover{
    transition: border-width 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-bottom: 5px solid ${colorPalette.primary};
    text{
      color:${colorPalette.primary};
      transition: color 0.6s linear;
    }
  }
`;

export const Text = styled.h3`
  display:flex;
  justify-content:center;
  font-size:18px;
  @media (max-width:860px) {
    font-size:14px;
  }
  margin-bottom:12px;
  color: ${({ active }) => active ? colorPalette.primary : colorPalette.disabledColor};
`;