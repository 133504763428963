import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';
import { Link } from 'gatsby'
export const Container = styled.header`
  display:flex;
  height: 220px;
  @media(max-width:860px){
    height:52px;
    justify-content:center;
  }
  flex-direction:column;
  flex:1;
  background-color:${colorPalette.primary};
`;

export const Wrapper = styled.div`
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  padding-top:30px;
`;

export const Nav = styled(Link)`
  color: ${colorPalette.white};
  margin:15px;
  font-weight:none;
`

export const SubTitle = styled.text`
  display:flex;
  color:${colorPalette.white};
  justify-content:center;
  font-weight:${({ bold }) => bold ? bold : 'normal'};
  font-size:18px;
  @media (min-width:468px){
    font-size:36px;
  }
`;
