import { useMediaQuery } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react'
import ReactPlayer from "react-player"
import { useSelector } from 'react-redux';
import { Container, Title, WrapperMobile, Button } from './styled'

export default function Tutorials() {

  const [platform, setPlatform] = useState('web');
  const [isActive, setActive] = useState({ web: true, mobile: false })
  const isMobile = useMediaQuery('(max-width:860px)');
  const [data, setData] = useState([]);
  const { role, product } = useSelector(state => state.app);

  const { v4h, v4hAtendimento } = useStaticQuery(
    graphql`
      query {
        v4h:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/tutorials/"}, frontmatter: {product: {eq: "V4H agenda"}}}) {
          edges {
            node {
              frontmatter {
                url
                title
                role
                product
                platform
              }
            }
          }
        },
        v4hAtendimento:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/tutorials/"}, frontmatter: {product: {eq: "V4H Atendimento"}}}) {
          edges {
            node {
              frontmatter {
                url
                title
                role
                product
                platform
              }
            }
          }
        },
      }
    `)

  useEffect(() => {
    filterProduct();
    return () => null
  }, []);

  function filterProduct() {
    if (product === "V4H agenda") {
      setData(v4h.edges);
    } else {
      setData(v4hAtendimento.edges);
    }
  }

  function handleClick(props) {
    if (props.web) {
      setPlatform('web');
    } else {
      setPlatform('mobile');
    }
    setActive(props);

  }
  function renderWeb() {
    let web;
    switch (role.name) {
      case "Paciente":
        web = data.filter(element => ('web' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Paciente"))
        break;
      case "Gestor":
        web = data.filter(element => ('web' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Gestor"))
        break;
      case "Profissional da saúde":
        web = data.filter(element => ('web' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Profissional da saúde"))
        break;
      default:
        return;
    }
    if (web.length <= 0 && isMobile) {
      return <text>Opss...ainda não temos essa informação </text>
    }
    let component = web.map(({ node: { frontmatter: { url, title } } }) => {
      return (
        <div>
          <ReactPlayer
            width={240}
            height={160}
            controls={true}
            url={url}
          />
          <p>{title}</p>
          <text>PC</text>
        </div>);
    })
    return component;
  }
  function renderMobile() {
    let mobile;
    switch (role.name) {
      case "Paciente":
        mobile = data.filter(element => ('mobile' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Paciente"))
        break;
      case "Gestor":
        mobile = data.filter(element => ('mobile' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Gestor"))
        break;
      case "Profissional da saúde":
        mobile = data.filter(element => ('mobile' === element.node.frontmatter.platform) && (element.node.frontmatter.role === "Profissional da saúde"))
        break;
      default:
        return;
    }
    if (mobile.length <= 0 && isMobile) {
      return <text>Opss...ainda não temos essa informação </text>
    }
    const component = mobile.map(({ node: { frontmatter: { url, title } } }) => {
      return (
        <div>
          <ReactPlayer
            width={240}
            height={160}
            controls={true}
            url={url}
          />
          <p>{title}</p>
          <text>Celular</text>
        </div>);
    })
    return component
  }
  function renderTutorialsMobile() {
    return <Container>

      <WrapperMobile>
        <div>
          <Button active={isActive.web} onClick={() => handleClick({ web: true, mobile: false })}>
            {/* <Img src={computerIcon} width={20} /> */}
            <text>Computador(PC)</text>
          </Button>

          <Button active={isActive.mobile} onClick={() => handleClick({ web: false, mobile: true })}>
            {/* <Img src={mobileIcon} width={13} /> */}
            <text>Celular</text>
          </Button>
        </div>
      </WrapperMobile>
      {isActive.web ? renderWeb() : renderMobile()}
    </Container>

  }
  function renderTutorialsWeb() {
    return (
      <Container>
        {renderMobile()}
        {renderWeb()}
      </Container>
    )
  }
  return (
    <>
      {/* {isMobile &&
        <WrapperMobile>
          <div>
            <Button active={isActive.web} onClick={() => handleClick({ web: true, mobile: false })}>
              <Img src={computerIcon} width={20} />
              <text>Computador(PC)</text>
            </Button>

            <Button active={isActive.mobile} onClick={() => handleClick({ web: false, mobile: true })}>
              <Img src={mobileIcon} width={13} />
              <text>Celular</text>
            </Button>
          </div>
        </WrapperMobile>} */}
      {isMobile ? renderTutorialsMobile() : renderTutorialsWeb()}
      {/* {data.map(({ node: { frontmatter: { url, title, platform } } }) => {

        return (
          <div>
            <ReactPlayer
              width={240}
              height={160}
              controls={true}
              url={url}
            />
            <p>{title}</p>
            { isMobile ? null : (platform === 'web' ? <text>PC</text> : <text>Celular</text>)}
          </div>);
      })} */}
    </>
  )
}
