/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import { Container, Content, CurrentRole, Menu, Nav, Navigator } from './styled'
import CategoryMobile from './mobile';
import { useMediaQuery } from '@material-ui/core';
import Questions from '../questions';
import Tutorials from '../tutorials';
import Posts from '../posts';
import { useSelector } from 'react-redux';

export default function Category() {

  const [active, setActive] = useState({ questions: true, tutorials: false, articles: false });
  const isMobile = useMediaQuery('(max-width:860px)');
  const { role } = useSelector(state => state.app);
  function renderContent() {
    if (active.questions) {
      return (
        <div id="questions">
          <h2>Principais dúvidas</h2>
          <p>Confira nossas instruções para as dúvidas mais frequentes</p>
          <Questions />
        </div>
      )
    }
    else if (active.tutorials) {
      return (
        <div id="tutorials">
          <h2>Tutoriais</h2>
          <p>Aqui você encontra tutoriais em vídeos com as principais dúvidas</p>
          <Tutorials />
        </div>
      );
    }
    else if (active.articles) {
      return (
        <div id="articles">
          <h2>Artigos</h2>
          <p>Artigos completos com detalhamentos de soluções para as dúvidas</p>
          <Posts />
        </div>
      )
    }

    return <text> error </text>

  }
  return (
    <Container>
      {isMobile ? <CategoryMobile /> :
        <>
          <Menu>
            <CurrentRole>
              <div>
                <text>{role.name}</text>
              </div>
            </CurrentRole>
            <Nav>
              <Navigator active={active.questions} onClick={() => {
                setActive({
                  questions: true,
                  tutorials: false,
                  articles: false,
                })
              }} >Principais dúvidas</Navigator>
              <Navigator active={active.tutorials} onClick={() => {
                setActive({
                  questions: false,
                  tutorials: true,
                  articles: false,
                })
              }}>Tutoriais</Navigator>
              <Navigator active={active.articles} onClick={() => {
                setActive({
                  questions: false,
                  tutorials: false,
                  articles: true,
                })
              }}>Artigos</Navigator>
            </Nav>
          </Menu>
          <Content>
            {renderContent()}
          </Content>
        </>
      }
    </Container>
  )
}
