import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '../../../../styles/colorSchema';

export const Container = styled.div`
  display:flex;
  flex-direction:row;
  @media (max-width: 860px) {
    justify-content:center;
  }
  
  /* min-width:860px; */
  margin-top:10px;
  color:${colorPalette.primary};
  font-weight:bold;
  div{
    
    flex:1;
    max-width: 380px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    div{
      display:flex;
      height: 20px;
      flex-direction:row;
    }
    
  }
`;

export const Img = styled.img`
  min-width:5px;
  margin:2px;
`;

export const Wrapper = styled(Link)`
  
  display:flex;
  text-decoration:none;
  flex:1;
  justify-content:space-around;
  align-items:center;
  outline:none;
  margin:8px auto 0 auto;
  min-height:42px;
  min-width:280px;
  max-width:480px;
  @media (max-width: 860px) {
    max-width:280px;
  }
  color: ${colorPalette.primary};
  border:1px solid ${colorPalette.primary};
  border-radius:5px;
  padding:10px;
  :hover{
    cursor:pointer;
    background-color: ${colorPalette.secondary};
  }

  div{
    min-height:5px;
    min-width: 5px;
  }

  h4{
    text-decoration:none;
    flex:1;
    flex-wrap:nowrap;
    color:inherit;
    text-decoration:none;
  }
`;
export const Title = styled.a`
  display:flex;
  flex:1;
  text-decoration:none;
  /* flex-wrap:wrap; */
`;
export const Button = styled.button`
  display:flex;
  border: ${({ active }) => active ? `1px solid ${colorPalette.primary}` : "none"};

  color:${colorPalette.primary};
  border-radius:5px;
  padding:1px;
  width:140px;
  height:40px;
  align-items:center;
  justify-content:center;
  font-size:12px;
  background-color:${colorPalette.white};
  :hover{
    opacity:0.5;
    cursor:pointer;
  }

`;

export const WrapperMobile = styled.div`
  display:flex;    
  flex-direction:column;
  min-width:280px;
  justify-content:center;
  div{
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-direction:row;
    background-color:${colorPalette.white};
    padding: 5px;
  }
`;