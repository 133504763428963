import styled from 'styled-components';
import { colorPalette } from '../../../../styles/colorSchema';

export const Container = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-left:auto;
  margin-right:auto;
  max-width:1144px;
  @media (max-width: 1023px) {
    justify-content:center;
    /* flex-direction:column; */
    padding-top:20px;
  }
  div{
    display:flex;
    align-items:center;
    flex-direction:column;
    color:${colorPalette.primary};
    font-weight:bold;
    /* margin:7px; */
    
    p{ 
      color: #000;
      flex-wrap:wrap;
      max-width:240px;
    }
  }
`;

export const Img = styled.img`
  min-width:5px;
  margin:5px;
`;

export const Button = styled.button`
   display:flex;
  border: ${({ active }) => active ? `1px solid ${colorPalette.primary}` : "none"};

  color:${colorPalette.primary};
  border-radius:5px;
  padding:1px;
  width:140px;
  height:40px;
  align-items:center;
  justify-content:center;
  font-size:12px;
  background-color:${colorPalette.white};
`;

export const Title = styled.text`
  color: black;
`;

export const WrapperMobile = styled.div`
  display:flex;    
  flex-direction:column;
  
  div{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:row;
    background-color:${colorPalette.white};
    padding: 5px;
  }
`;