import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  @media (min-width: 1040px) {
    justify-content:center;
  }
  /* align-items:center; */
  flex-direction:column;
`;
