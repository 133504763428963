import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

import { Container, Text, Wrapper } from './styled'
import { currentRole as creators } from '../../../../state/app';
export default function Role() {
  const [role, setState] = useState(
    [
      { name: "Paciente", active: true, },
      { name: "Gestor", active: false },
      { name: "Profissional da saúde", active: false }
    ]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    role.map((element) => {
      if (element.active) {

        dispatch(creators(element))
      }

    });

  }, [role]);

  return (
    <Container>
      <Wrapper active={role[0].active} onClick={() => {
        setState([
          { name: "Paciente", active: true, },
          { name: "Gestor", active: false },
          { name: "Profissional da saúde", active: false },
        ]);
      }}>
        <Text active={role[0].active}>{role[0].name}</Text>
      </Wrapper>
      <Wrapper active={role[1].active} onClick={() => {
        setState([
          { name: "Paciente", active: false, },
          { name: "Gestor", active: true },
          { name: "Profissional da saúde", active: false },
        ]);
      }}>
        <Text active={role[1].active}>{role[1].name}</Text>
      </Wrapper>
      <Wrapper active={role[2].active} onClick={() => {
        setState([
          { name: "Paciente", active: false, },
          { name: "Gestor", active: false },
          { name: "Profissional da saúde", active: true },
        ]);
      }}>
        <Text active={role[2].active}>{role[2].name}</Text>
      </Wrapper>
    </Container>
  )
}
