import React from 'react'
import { Container, PostItemDescription, PostItemTitle } from './styled'

export default function PostItem({ slug, title, description }) {
  return (
    <Container to={slug}>
      <PostItemTitle>{title}</PostItemTitle>
      <PostItemDescription>{description}</PostItemDescription>
    </Container>
  )
}
