import styled from 'styled-components';
import { colorPalette } from '../../../../styles/colorSchema';

export const Container = styled.div`
  display:flex;
  @media(max-width:860px ) {
    flex-direction:column;
  }

  color: ${colorPalette.black};
  margin-top:20px;
`;

export const Menu = styled.div`
  /* max-width:280px; */
  width:210px;
  height: 100%;
  background-color:${colorPalette.background};
  margin-top: 10px; /* altura que está do topo */
  top: 90px; /* altura que vai parar antes do topo */
  position: sticky;
`;

export const Nav = styled.ul`
  list-style:none;
  background-color: ${colorPalette.background};
  padding:10px 5px 10px 5px;
    a{
      flex-wrap:nowrap;
      color:inherit;
      text-decoration:none;
    }
`;

export const Navigator = styled.li`
  display:flex;
  color: ${({ active }) => active ? '#000' : colorPalette.disabledColor} ;
  list-style:none;
  margin:20px 50px 10px 20px;
  &:hover{
    color:#000;
    cursor:pointer;
  }
  padding:10px px 10px 5px;
`;

export const Content = styled.div`
  
  width:860px;
  
  @media(max-width:1024px) {
    width:unset;    
  }
  div{
    padding:5px;
    h6{
      color: ${colorPalette.disabledColor};
    }
  }
`;

export const CurrentRole = styled.div`
  
  color:${colorPalette.primary};
  padding-left:15px;
  div{
    width:145px;
    border-bottom: 2px solid ${colorPalette.primary};
    padding-bottom:10px;
    text{
      font-size:14px;
      margin:5px;
    }
  }
`;