import React from 'react'
import NavBar from '../nav-bar'
import Img from 'gatsby-image'
import { Container, Wrapper, Nav } from './styled'
import { useStaticQuery } from 'gatsby'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'

export default function HeaderFaq() {
  const { product } = useSelector(state => state.app);
  const isMobile = useMediaQuery('(max-width:860px)');
  const { v4h, v4hAtendimento } = useStaticQuery(
    graphql`
      query{
        v4h: file(relativePath:{eq:"logo-v4h.png"}){
          childImageSharp{
            fixed( width: 185, height: 86){
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        },
        v4hAtendimento: file(relativePath:{eq:"logo-v4h-atendimento.png"}){
          childImageSharp{
            fixed( width: 186, height: 86){
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        },
      }
    `
  )
  return (
    <Container>
      <NavBar isFaq={true} />
      {!isMobile && <Wrapper>
        {product === "V4H agenda" ? <Img fixed={v4h.childImageSharp.fixed} /> :
          <Img fixed={v4hAtendimento.childImageSharp.fixed} />}
        <Nav to="/">
          <h4>Selecionar outro produto</h4>
        </Nav>
      </Wrapper>}
    </Container>
  )
}
