import React from 'react'
import BodyFaq from '../components/body-faq'
import HeaderFaq from '../components/header-faq';
import Global from '../styles/global'

export default function Faqs() {

  return (
    <div>
      <Global />
      <HeaderFaq />
      <BodyFaq />
    </div>
  )
}
