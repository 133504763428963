import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PostItem from '../../../post-item';
import { Container } from './styled';
import { useSelector } from 'react-redux';

export default function Posts() {

  const { role, product } = useSelector(state => state.app);
  const [data, setData] = useState([]);
  const { v4h, v4hatendimento } = useStaticQuery(
    graphql`
  query MyQuery {
    v4h: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/"}, frontmatter: {product: {eq: "V4H agenda"}}}) {
      edges {
        node {
          frontmatter {
            title
            description
            date(formatString: "DD [de ] MMMM [de] YYYY", locale: "pt-br")
            product
            role
          }
          fields{
            slug
          }
          timeToRead
        }
      }
    },
    v4hatendimento: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/"}, frontmatter: {product: {eq: "V4H Atendimento"}}}) {
      edges {
        node {
          frontmatter {
            title
            description
            date(formatString: "DD [de ] MMMM [de] YYYY", locale: "pt-br")
            product
            role
          }
          fields{
            slug
          }
          timeToRead
        }
      }
    }
  }
    `
  )

  useEffect(() => {

    filterProduct();

    return () => null
  }, [role])

  function filterProduct() {

    if (product === "V4H agenda") {
      setData(v4h.edges);
    } else {
      setData(v4hatendimento.edges)
    }
  }

  function renderPost() {
    let list;
    switch (role.name) {
      case "Paciente":
        list = data.filter(element => element.node.frontmatter.role === "Paciente")
        break;
      case "Gestor":
        list = data.filter(element => element.node.frontmatter.role === "Gestor")
        break;
      case "Profissional da saúde":
        list = data.filter(element => element.node.frontmatter.role === "Profissional da saúde")
        break;
      default:
        return;
    }

    let component = list.map(({
      node: {
        frontmatter: { title, date, description },
        timeToRead,
        fields: { slug } } }) => {
      return (
        <PostItem
          slug={slug}
          title={title}
          description={description}
        />)
    })
    return component;
  }

  return (
    <Container>
      {renderPost()}
    </Container>
  )
}
